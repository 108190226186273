import requestReducer, { initialRequest } from '@reducers/requestReducer';
import React, { useReducer, useState } from 'react';
import {RequestContext} from './requestContext';

const RequestContextProvider = ({ children }:any) => {
    //Attributes
    const [request, dispatch] = useReducer(requestReducer.reducer, initialRequest);
    //Render
    return (
        <RequestContext.Provider value={{request, dispatch}} >
            {children}
        </RequestContext.Provider>
    );
}

export default RequestContextProvider;