// modules
import React, { Fragment, useCallback, useEffect } from "react";

// style
import "./style.css";
import InputProps from "./props";
import { useTranslation } from "@components/translation-context/TranslationContext";
import ReactDatePicker from "react-datepicker";
import ReactInputMask from "react-input-mask";

const Input = (props: InputProps) => {

  // Attributes
  const {
    name,
    type,
    placeholder,
    value,
    onChange,
    onFocus,
    autoFocus,
    onKeyPress,
    error,
    register,
    formProps,
    errorMessage,
    isHidden,
    setValue,
    minValue,
    style,
    mask,
    ...rest
  } = props;

  const { translate } = useTranslation();

  const ref = register && register(formProps);

  const maskedInput = useCallback(() => (
    <div className='input-container'>
      <input
        ref={ref}
        name={name}
        className={`${type === 'textarea' ? "textarea" : name} ${error ? "input-error" : ""}`}
        type={type}
        value={value}
        placeholder={placeholder}
        onKeyPress={onKeyPress}
        autoComplete="false"
        style={style}
      />
      <p className="error">{error && errorMessage ? translate(errorMessage) : " "}</p>
    </div>
  ), [error, value])

  // Effects
  useEffect(() => {
    if (autoFocus) {
      ref?.current?.focus();
    }
  }, [])

  // Functions
  function handleChange(event: any) {
    if (setValue) {
      setValue(name, event?.target?.value);
    }
    onChange(event);
  }

  if (isHidden) {
    return <Fragment />
  }

  const ExampleCustomInput = ({ value, onClick, className }: any) => (
    <div style={style} className="input-container_custom" onClick={onClick}>
      <input
        style={{ ...style, cursor: 'pointer' }}
        {...props}
        className={`${type === 'textarea' ? "textarea" : 'datePicker'} ${error ? "input-error" : ''}`}
        type="text"
        value={value}
        autoComplete="off"
        onChange={handleChange}
        name={name}
        onMouseDown={(e) => e.preventDefault()}
        placeholder={translate(props.placeholder ?? "")}
      />
      <p className="error">{error && errorMessage ? translate(errorMessage) : " "}</p>
    </div>
  );




  const monInput = maskedInput();

  if (mask) {
    return (
      <ReactInputMask onChange={handleChange} value={value} mask={mask}>
        {
          () => monInput
        }
      </ReactInputMask>
    )
  }

  if (type === "date") {
    return (
      <ReactDatePicker
        ref={ref}
        name={name}
        minDate={minValue}
        customInput={<ExampleCustomInput className='datePicker' noError />}
        className="input-container-datepicker"
        dateFormat="dd/MM/yyyy"
        isClearable={true}
        popperModifiers={{
          preventOverflow: {
            enabled: true
          }
        }}
        selected={value ? value as any : undefined}
        onChange={(date: any) => handleChange({ target: { name: name, value: date, type: 'date' } })}
      />
    )
  }



  return (
    <div style={style} className={`input-container ${name?.includes('startDate') ? 'datePicker' : ''}`}>
      <input
        ref={ref}
        name={name}
        className={`${type === 'textarea' ? "textarea" : name} ${error ? "input-error" : ""}`}
        onFocus={onFocus}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        onKeyPress={onKeyPress}
        autoComplete="false"
        style={style}
      />
      <p className="error">{error && errorMessage ? translate(errorMessage) : " "}</p>
    </div>
  );
};

export default Input;
