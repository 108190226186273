import { useTranslation } from '@components/translation-context/TranslationContext';
import React, { useEffect } from 'react';
import SelectProps from "./props";
import './style.css';

const Select = (props: SelectProps) => {

    // Attributes
    const {
        name,
        placeholder,
        value,
        onChange,
        options,
        error,
        register,
        formProps,
        errorMessage,
        isHidden,
    } = props;

    const { translate } = useTranslation();    

    // Methods
    function handleChange(event: any) {
        onChange(event);
    }

    function getOptions() {
        const computedOptions: any[] = [];

        computedOptions.push(<option value={-1} disabled>{placeholder}</option>);

        for (let i = 0; i < options.length; i++) {
            computedOptions.push(<option value={options[i].value}>{options[i].label}</option>);
        }

        return computedOptions;
    }

    if (isHidden) {
        return (
            <React.Fragment />
        )
    }

    return (
        <div className="select-container">
            <select
                style={ value == -1 ? {color: '#B4B4B4'} : {}}
                ref={register && register(formProps)}
                name={name}
                className={`select ${error ? "select-error" : ""} ${value == -1 ? "select-disabled" : ""}`}
                value={value}
                onChange={handleChange}
                placeholder={placeholder}
            >
                {getOptions()}
            </select>
            <p className="error">{error && errorMessage ? translate(errorMessage) : ""}</p>
        </div>
    );
}

export default Select;