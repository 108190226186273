import Service from "./Service";
import Axios from "axios";
import RequestDTO from "@dtos/RequestDTO";
import AddressDTO from "@dtos/AddressDTO";
import _ from "lodash";

async function post(request: RequestDTO) {
  var tempRequest = _.cloneDeep(request);

  const addressDto: AddressDTO = {
    streetName: tempRequest.worker.address,
  };

  tempRequest.worker.address = addressDto;
  tempRequest.authorizedLeave = tempRequest.authorizedLeave == 1 ? true : false;
  
  return Service.execute(
    Axios.post(
      `${process.env.REACT_APP_RESOURCE_SERVER}/${process.env.REACT_APP_COMPANY_ID}/accidents`,
      tempRequest
    )
  );
}

export default { post };
