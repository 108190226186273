import { useTranslation } from '@components/translation-context/TranslationContext';
import React from 'react';
import './style.css'

const ConfirmationInfo = () => {
    //Attributes
    const {translate} = useTranslation();

    return (
        <div>
            <p className='confirmationInfo'> { translate('confirmationInfoP1') }</p>
            <p className='confirmationInfo'> { translate('confirmationInfoP2') }</p>
        </div>
    )
}

export default ConfirmationInfo;