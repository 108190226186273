import { useTranslation } from '@components/translation-context/TranslationContext';
import React from 'react';
import RecapProps from './props';
import './style.css';

const RecapData = (props: RecapProps) => {
    //Attributes
    const { translate } = useTranslation();
    const {
        data
    } = props;

    function formatDate(date: Date) {
        let year = date.getFullYear();

        let month: any = date.getMonth() + 1;
        if (month < 10) {
            month = "0" + month;
        }

        let day: any = date.getDate();
        if (day < 10) {
            day = "0" + day;
        }

        return `${day}/${month}/${year}`;
    }

    return (
        <div className='data-container'>
            <p className='data-text'> {translate('workerNissPlaceholder').split('*')[0]} : <strong> {data.worker.niss} </strong> </p>
            <p className='data-text'> {translate('addressPlaceholder').split('*')[0]} : <strong> {data.worker.address.streetName ?? data.worker.address} </strong> </p>
            <p className='data-text'> {translate('workerPhoneNumberPlaceholder').split('*')[0]} : <strong> {data.worker.phoneNumber} </strong> </p>
            <p className='data-text'> {translate('accidentDatePlaceholder').split('*')[0]} : <strong> {formatDate(new Date(data.accidentDate))} </strong> </p>
            <p className='data-text'> {translate('startDatePlaceholder').split('*')[0]} : <strong> {formatDate(new Date(data.startDate))} </strong> </p>
            <p className='data-text'> {translate('endDatePlaceholder').split('*')[0]} : <strong> {formatDate(new Date(data.endDate))} </strong> </p>
        </div>
    );
}

export default RecapData;