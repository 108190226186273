import React from 'react';
import { Route, Switch } from 'react-router';
import AlternativePlus30 from './modules/30j-alternative';
import Alternative from './modules/alternative';
import DefaultConfirmation from './modules/default';

const Confirmation = () => {
    return(
        <Switch>
            <Route path='/confirmation/plus30' component={AlternativePlus30} />
            <Route path='/confirmation/alt' component={Alternative} />
            <Route exact path='/confirmation' component={DefaultConfirmation} />
        </Switch>
    );
}

export default Confirmation;