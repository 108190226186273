import React, { useEffect, useState } from "react";
import { useTranslation } from "@components/translation-context/TranslationContext";
import Logo from "./assets/medicheckLogo.svg";
import "./style.css";

const Footer = () => {
  //Attributes
  const { translate, language } = useTranslation();
  const [privacyPolicyLink, setPrivacyPolicyLink] = useState("");

  //Effects
  useEffect(() => {
    switch (language) {
      case "fr":
        setPrivacyPolicyLink(
          "https://storage.cloud.google.com/medicheck_templates/legal/Medicheck_Privacy_Policy_FR.pdf"
        );
        break;
      case "nl":
        setPrivacyPolicyLink(
          "https://storage.cloud.google.com/medicheck_templates/legal/Medicheck_Privacy_Policy_NL.pdf"
        );
        break;
      case "en":
      case "de":
        setPrivacyPolicyLink(
          "https://storage.cloud.google.com/medicheck_templates/legal/Medicheck_Privacy_Policy_EN.pdf"
        );
        break;
      default:
        break;
    }
  }, [language]);

  return (
    <div className="footer">
      <div className="footer-collab">
        <p className="footer-text">{translate("footerCollaboration")}</p>
        <img
          src={Logo}
          alt=""
          style={{ maxHeight: "11,31px", maxWidth: "62px" }}
        />
      </div>
      <a
        style={{ color: "inherit", textDecoration: "none" }}
        href={privacyPolicyLink}
        target="_blank"
      >
        <p className="footer-text" style={{ fontSize: "9px" }}>
          {" "}
          {translate("footerCopywrite")}{" "}
        </p>
      </a>
    </div>
  );
};

export default Footer;
