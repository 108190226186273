import Box from '@components/box';
import PageHeader from '@components/page-header';
import { useTranslation } from '@components/translation-context/TranslationContext';
import React from 'react';
import ConfirmationInfo from './components/confirmation-info';
import ConfirmationImage from './assets/confirmationImage.svg'
import './style.css';
import Footer from '@components/footer';

const DefaultConfirmation = () => {
    //Attributes
    const { translate } = useTranslation();

    return (
        <div className="confirmationFlex">
            <Box>
                <Box type="boxinside">
                    <PageHeader subTitle={translate("defaultConfirmation")} />
                    <div className="confirmation-box">
                        <ConfirmationInfo />
                    </div>
                </Box>
            </Box>
            <Footer />
        </div>
    )
}

export default DefaultConfirmation;