import Box from '@components/box';
import Footer from '@components/footer';
import PageHeader from '@components/page-header';
import { useTranslation } from '@components/translation-context/TranslationContext';
import React from 'react';
import './style.css';

const Alternative = () => {
    //Attributes
    const { translate } = useTranslation();

    return(
        <div className="altFlex">
            <Box>
                <Box type="boxinside">
                    <PageHeader subTitle={translate("alternativeConfirmation")} />
                    <div className="confirmation-alt--box">
                        <p className='conf-alt-text'>{translate('conf-alt-p1')}</p>
                        <p className='conf-alt-text'>{translate('conf-alt-p2')}</p>
                    </div>
                </Box>
            </Box>
            <Footer />
        </div>
    );
}

export default Alternative;