import RequestDTO from '@dtos/RequestDTO';
import { initialRequest, requestReducerAction } from '@reducers/requestReducer';
import { createContext, useContext, useState } from 'react';

interface IRequestContext {
    request: RequestDTO,
    dispatch: (action:requestReducerAction) => void
}

export const RequestContext = createContext<IRequestContext>({
    request: initialRequest,
    dispatch: () => { }
});

export function useRequest() {
    return useContext(RequestContext);
}
