import * as Sentry from "@sentry/react";

let errors: string[] = [];

function config() {
    const access_token = localStorage.getItem("ACCESS_TOKEN");
    return { headers: { 'Authorization': `bearer ${access_token}` } };
}

async function execute(service: Promise<any>) {
    try {
        return (await service).data;
    }
    catch (e) {
        if (e?.response?.status === 401) {
            localStorage.clear();
            window.location.pathname = "/employee";
        }
        else {
            errors.push(e?.response?.data);
        }
        Sentry.captureException(e);
    }
}

const API_URL = process.env.REACT_APP_RESOURCE_SERVER;

export default { errors, execute, config, API_URL };