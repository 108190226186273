import React from 'react';
import NissImage from './assets/niss-image.jpg';

const NissImageExemple = () => {
    return (
        <div>
            <img src={NissImage} alt="niss-image-exemple"/>
        </div>
    )
}

export default NissImageExemple;