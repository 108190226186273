import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader';
import './App.css';
import { TranslationContextProvider } from './components/translation-context';
import { Switch, Route, BrowserRouter, useHistory } from 'react-router-dom';
import * as Sentry from "@sentry/react";


// modules

import "react-datepicker/dist/react-datepicker.css";
import { ReactQueryConfigProvider } from 'react-query';
import Worker from '@modules/worker';
import Intro from '@modules/intro';
import NISS from '@modules/niss';
import Incapacity from '@modules/incapacity';
import Recap from '@modules/recap';
import Confirmation from '@modules/confirmation';
import Alternative from '@modules/confirmation/modules/alternative';
import RequestContextProvider from './context/requestContextProvider';
import NissImageExemple from '@modules/niss/components/niss-image-exemple';
import Shell from './modules';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  // Rendering
  return (
    <ReactQueryConfigProvider config={{ queries: { refetchOnMount: true, refetchOnWindowFocus: false } }}>
      <TranslationContextProvider>
        <RequestContextProvider>
          <BrowserRouter>
            <Switch>
              <Route path='/' component={Shell} />
            </Switch>
          </BrowserRouter>
          <ToastContainer />
        </RequestContextProvider>
      </TranslationContextProvider>
    </ReactQueryConfigProvider>
  );
}

export default hot(module)(App);