import "./style.css";
import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "@components/translation-context/TranslationContext";
import Box from "@components/box";
import PageHeader from "@components/page-header";
import LanguagePanel from "@components/language-panel";
import BoxFooter from "@components/box-footer";
import Footer from "@components/footer";

const Intro = () => {
  //Attributes
  const history = useHistory();
  const { translate } = useTranslation();

  //Functions
  function handleOnClickNext() {
    history.push("/worker");
  }

  //Render
  return (
    <div className="infoFlex">
      <Box>
        <LanguagePanel />
        <Box type="boxinside">
          <PageHeader subTitle={translate("intro")} />
          <div className="intro-box">
            <div className="intro-detail">
              <p
                style={{
                  fontSize: "15x",
                  fontWeight: 500,
                  lineHeight: "16px",
                  marginBottom: "17px",
                  color: "rgba(232, 78, 15, 1)",
                }}
              >
                {translate("introTitle")}
              </p>
              <p
                style={{
                  fontSize: "13px",
                  fontWeight: 500,
                  lineHeight: "14px",
                  marginBottom: "14px",
                  color: "#185da9",
                }}
              >
                {translate("introSubTitle")}
              </p>
              <div className="intro-detail-text">
                <p
                  style={{
                    fontSize: "13px",
                    fontWeight: 300,
                    lineHeight: "14px",
                    marginBottom: "14px",
                  }}
                >
                  {translate("introDetail")}
                </p>
                <p
                  style={{
                    fontSize: "13px",
                    fontWeight: 300,
                    lineHeight: "14px",
                  }}
                >
                  {translate("introDetailPart2")}
                </p>
              </div>
            </div>
            <BoxFooter
              step={0}
              showBack={false}
              onClickNext={handleOnClickNext}
            />
          </div>
        </Box>
      </Box>
      <Footer />
    </div>
  );
};

export default Intro;
