import AddressDTO from "./AddressDTO";

export default interface WorkerDTO {
    firstName: string,
    lastName: string,
    niss: string,
    gender: Gender,
    function?: string,
    language?: string
    languages: string[],
    phoneNumber: string,
    address: any,
    employer: string | number
}

export enum Gender {
    UNKNOWN = -1,
    MALE,
    FEMALE,
    OTHER
}