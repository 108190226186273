export enum IncapacityType {
    Unknown = -1,
    Illness,
    WorkAccident,
    PrivateAccident,
    OccupationalIllness,
    SurgicalIntervention,
    Hospitalization,
    MaternityLeave,
    Quarantine
}