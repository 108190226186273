import Box from '@components/box';
import BoxFooter from '@components/box-footer';
import Input from '@components/input';
import PageHeader from '@components/page-header';
import { useTranslation } from '@components/translation-context/TranslationContext';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import './style.css';
import Warning from './assets/warning.svg';
import { actions } from '@reducers/requestReducer';
import { useRequest } from '../../context/requestContext';
import Footer from '@components/footer';

const NISS = () => {
	//Attributes
	const { translate } = useTranslation();
	const history = useHistory();
	const {
		register,
		errors,
		handleSubmit,
		setValue,
		getValues,
		clearErrors,
		setError,
	} = useForm({ shouldFocusError: false, reValidateMode: 'onSubmit' });
	const { request, dispatch } = useRequest();

	//Effects
	useEffect(() => {
		clearErrors();
	}, []);

	//Functions
	function handleOnClickNext() {
		let neufPremierNiss = request.worker.niss
			.substring(0, 12)
			.replace(/[^0-9]/g, '');

		// if NISS number starts with 0 then add the digit 2 in front of the 9 digits
		if (neufPremierNiss.startsWith('0')) {
			neufPremierNiss = '2' + neufPremierNiss;
		}

		const chiffreControle = 97 - (Number(neufPremierNiss) % 97);
		const nissLength = request.worker.niss.split('.').length;
		const nissChiffreControle = Number(
			request.worker.niss.split('.')[nissLength - 1]
		);

		if (chiffreControle !== nissChiffreControle) {
			setError('workerNiss', {
				type: 'validate',
				message: translate('nissError'),
			});
			return;
		}

		history.push('/incapacity');
	}

	function handleOnClickBack() {
		history.goBack();
	}

	function handleChange(event: any, type: string) {
		const action = { type, payload: event?.target.value };
		dispatch(action);
		setValue(event.target.name, event.target.value);
		if (event.target.value !== '') {
			clearErrors('workerNiss');
		}
	}

	function retrieveValue(name: string) {
		if (getValues(name)) {
			return getValues(name);
		} else {
			switch (name) {
				case 'workerNiss':
					setValue(name, request.worker.niss);
					return request.worker.niss;
				case 'default':
					break;
			}
		}
	}

	function renderInput(name: string, type?: string, mask?: string) {
		// Attribtues
		const action = (actions as any)[`${name}Changed`];
		const errorMessage =
			errors[name]?.type === 'pattern'
				? translate('patternError')
				: translate('errorInput');
		const isHidden = false;
		const formProps = {
			pattern: {
				value:
					/^[0-9]{2}[.\- ]{0,1}(00|0[1-9]|1[0-2]|2[0-9]|3[0-2]|4[0-9]|5[0-2])[.\- ]{0,1}(0[1-9]|[12]\d|3[01]|00)[.\- ]{0,1}[0-9]{3}[.\- ]{0,1}[0-9]{2}$/,
			},
			required: true,
		};

		// Render
		return (
			<Input
				type={type ? type : 'text'}
				name={'workerNiss'}
				isHidden={isHidden}
				value={retrieveValue(name)}
				setValue={setValue}
				onChange={(event) => handleChange(event, action)}
				placeholder={translate(`${name}Placeholder`)}
				error={errors[name]}
				errorMessage={errorMessage}
				register={register}
				formProps={formProps}
				mask={mask ? mask : ''}
			/>
		);
	}

	return (
		<div className='nissFlex'>
			<Box>
				<Box type='boxinside'>
					<PageHeader subTitle={translate('niss')} />
					<div className='niss-box'>
						{renderInput('workerNiss', 'text', '99.99.99-999.99')}
						<div className='niss-detail'>
							<p
								style={{
									fontSize: '13px',
									fontWeight: 500,
									lineHeight: '14px',
									marginBottom: '8px',
									color: 'rgba(226, 69, 17, 1)',
								}}
							>
								{translate('nissDetailTitle')}
							</p>
							<div className='niss-detail-text'>
								<p
									style={{
										fontSize: '13px',
										fontWeight: 300,
										lineHeight: '14px',
										marginBottom: '14px',
									}}
								>
									{translate('nissDetailText')}
								</p>
								<p
									style={{
										fontSize: '13px',
										fontWeight: 300,
										lineHeight: '14px',
									}}
								>
									{translate('nissDetailSubText')}{' '}
									<a target='_blank' href='./niss/exemple'>
										{translate('ici')}
									</a>
								</p>
							</div>
						</div>
						<div className='niss-important'>
							<img
								src={Warning}
								alt='warning'
								style={{
									maxHeight: '39px',
									maxWidth: '39px',
									marginRight: '14px',
								}}
							/>
							<p
								style={{
									fontSize: '13px',
									margin: 'auto',
									fontWeight: 300,
									lineHeight: '14.06px',
								}}
							>
								{translate('nissImportantText')}
							</p>
						</div>
						<BoxFooter
							step={2}
							errorsLength={Object.keys(errors).length}
							onClickNext={handleSubmit(handleOnClickNext)}
							onClickBack={handleOnClickBack}
						/>
					</div>
				</Box>
			</Box>
			<Footer />
		</div>
	);
};

export default NISS;
