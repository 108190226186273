import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/string/repeat'

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createBrowserHistory } from 'history';

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

const history = createBrowserHistory();

Sentry.init({
  dsn: "https://7414df14b357496b82bf8e585cdb779f@o503027.ingest.sentry.io/6137203",
  integrations: [new Integrations.BrowserTracing({
    tracingOrigins: ["https://medicheck-external-staging.azurewebsites.net"],
  })],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();