import Button from '@components/button';
import Stepper from '@components/stepper';
import React from 'react';
import { Props } from './props';
import './style.css';

const BoxFooter = (props: Props) => {
    //Attributes
    const {
        showBack = true,
        step,
        onClickNext,
        onClickBack,
        btnEnvoyer,
        errorsLength = 0,
        isLoading = false
    } = props;

    function setTranslation(){
        return isLoading ? "loading" : "envoyerPlaceholder";
    }

    return (
        <div className="box-footer">
                <div className={showBack ? "btnBack" : "btnBackDisabled" }>
                    <Button type="back" onClick={onClickBack}>
                        validerPlaceholder
                    </Button>
                </div>
                <div className="stepper">
                    <Stepper step={step} steps={5} />
                </div>
                <div className={btnEnvoyer ? "none" : "btnNext"}>
                    <Button disabled={errorsLength === 0 ? false : true} type="next" onClick={onClickNext}>
                        validerPlaceholder
                    </Button>
                </div>
                <div className={btnEnvoyer ? "btnEnvoyer" : "none"}>
                    <Button disabled={errorsLength !== 0 || isLoading ? true : false} type='valider' onClick={onClickNext}>
                        {setTranslation()}
                    </Button>
                </div>
            </div>
    )
}

export default BoxFooter;