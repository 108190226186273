import React from 'react';
import './style.css'
import Props from './props';
import NextArrow from './assets/next-arrow.svg';
import BackArrow from './assets/back-arrow.svg';
import { useTranslation } from '@components/translation-context/TranslationContext';

const Button = (props: Props) => {
  // Attributes
  const { disabled, onClick, type, isNavigation } = props;
  const {translate} = useTranslation();
  

  function findClassName() {
    let className = "button";

    if (type) {
      className += ` ${type}`;
    }

    else {
      className += ` default`;
    }

    if (isNavigation) {
      className += ' navigation';
    }


    if (disabled) {
      className += " disabled";
    }
    return className;
  }

  function handleButtonClicked() {
    if (!disabled) {
      onClick();
    }
  }

  function findTextClassName() {
    if (findClassName().includes('disabled')) {
      return 'button-text disabled'
    } else {
      return 'button-text'
    }
  }

  return (
    <div className={findClassName()} onClick={handleButtonClicked}>
      <div className={ findTextClassName()}>
        {
          type === 'next' ? <img src={NextArrow} alt=""/> : 
          type === 'back' ? <img src={BackArrow} alt=""/> :
          translate(props.children)
        }
        
      </div>
    </div>
  )
}

export default Button;