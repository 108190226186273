import Box from "@components/box";
import BoxFooter from "@components/box-footer";
import Footer from "@components/footer";
import Input from "@components/input";
import PageHeader from "@components/page-header";
import { useTranslation } from "@components/translation-context/TranslationContext";
import RequestDTO from "@dtos/RequestDTO";
import { actions } from "@reducers/requestReducer";
import RequestService from "@services/RequestService";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { useRequest } from "../../context/requestContext";
import RecapData from "./component/recap-data";
import "./style.css";
import { toast } from "react-toastify";

const Recap = () => {
  //Attributes
  const { translate, language } = useTranslation();
  const history = useHistory();
  const { request } = useRequest();
  const [radio, setRadio] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //Functions
  async function handleOnClickNext() {
    //submit all data & go confirmation
    if (radio) {
      setIsLoading(true);
      const response = await postRequest(request);
      setIsLoading(false);
      setShowError(false);

      if (response) {
        const differenceInMilliseconds =
          new Date(request.startDate).getTime() -
          new Date(request.accidentDate).getTime();
        const differenceInDays =
          differenceInMilliseconds / (1000 * 60 * 60 * 24);

        if (request.authorizedLeave == 0) {
          history.push("/confirmation/alt");
          return;
        } else if (differenceInDays > 30) {
          history.push("/confirmation/plus30");
          return;
        } else {
          history.push("/confirmation");
          return;
        }
      } else {
        toast.error(translate("errorRequest"), {
          hideProgressBar: true,
          autoClose: 2000,
        });
        return;
      }
    } else {
      setShowError(true);
    }
  }

  function formatDate(date: Date) {
    let year = date.getFullYear();

    let month: any = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }

    let day: any = date.getDate();
    if (day < 10) {
      day = "0" + day;
    }

    return `${year}-${month}-${day}T00:00:00`;
  }

  const postRequest = async (request: RequestDTO) => {
    request.worker.language = language;
    request.startDate = formatDate(new Date(request.startDate));
    if (request.endDate) {
      request.endDate = formatDate(new Date(request.endDate));
    }
    request.accidentDate = formatDate(new Date(request.accidentDate));

    if (request.worker.employer === null || request.worker.employer === "-1") {
      toast.error(translate("errorRequest"), {
        hideProgressBar: true,
        autoClose: 2000,
      });
      return;
    }

    return await RequestService.post(request);
  };

  const handleOnClickBack = () => {
    history.goBack();
  };

  const handleRadioChange = () => {
    setRadio(!radio);
    if (radio) {
      setShowError(false);
    }
  };

  return (
    <div className="recapFlex">
      <Box>
        <Box type="boxinside">
          <PageHeader subTitle={translate("recap")} />
          <div className="recap-box">
            <div className="recap-data">
              <p
                style={{
                  fontSize: "13px",
                  fontWeight: 300,
                  lineHeight: "14.06px",
                  marginBottom: "15px",
                }}
              >
                {translate("recap-confirmation")}
              </p>
              <RecapData data={request} />
            </div>
            <div className="recap-warning">
              <div style={{ display: "flex" }} onClick={handleRadioChange}>
                <input
                  id="recap-radio"
                  type="radio"
                  checked={radio}
                  name="recap-radio"
                />
              </div>
              <p
                style={{
                  fontSize: "13px",
                  fontWeight: 400,
                  lineHeight: "14.06px",
                  margin: "auto",
                }}
              >
                {translate("recap-warning-info")}
              </p>
            </div>

            <p
              className={showError ? "recapRadioError" : "recapRadioErrorHide"}
            >
              {" "}
              {translate("recapRadioError")}{" "}
            </p>

            <div className="recap-info">
              <p
                style={{
                  fontSize: "13px",
                  fontWeight: 300,
                  lineHeight: "14.06px",
                  margin: "auto",
                }}
              >
                {translate("recap-info")}{" "}
                <a
                  target="_blank"
                  href={
                    "https://storage.cloud.google.com/medicheck_templates/legal/Medicheck_Privacy_Policy_" +
                    language.toLowerCase()
                  }
                >
                  {translate("politique-vie")}
                </a>
              </p>
            </div>
            <BoxFooter
              isLoading={isLoading}
              btnEnvoyer
              errorsLength={radio ? 0 : 1}
              step={4}
              onClickNext={handleOnClickNext}
              onClickBack={handleOnClickBack}
            />
          </div>
        </Box>
      </Box>
      <Footer />
    </div>
  );
};

export default Recap;
