import { useTranslation } from "@components/translation-context/TranslationContext";
import React from "react";
import Logo from "./assets/logo.svg"
import "./style.css";

const PageHeader = ({ subTitle }: any) => {
  //Attributes
  const {translate} = useTranslation();

  return (
    <div className="page-header">
      <div className="title" style={{color: "white"}}>
        <span style={{fontSize: "11px", fontWeight: 300}}>{translate("title")}</span>
        <h1 style={{fontSize: "16px"}}>{subTitle}</h1>
      </div>
      <div>
        <img src={Logo} alt="" style={{maxHeight: "49px", maxWidth: "76px", marginBottom: "-4px"}} />
      </div>
    </div>
  );
};

export default PageHeader;
