import React from 'react';
import './style.css';
import DE from './assets/de.png';
import NL from './assets/nl.png';
import EN from './assets/en.png';
import FR from './assets/fr.png';
import { useTranslation } from '@components/translation-context/TranslationContext';

const LanguagePanel = () => {
    //Attributes
    const languages = [
        {name: "fr", image: FR},
        {name: "nl", image: NL},
        {name: "en", image: EN},
        {name: "de", image: DE},
    ];
    const {language, changeLanguage} = useTranslation();

    return (
        <div className="language-panel">
            {
                languages.filter(elt => elt.name !== language)
                .map(elt => (
                    <div onClick={() => changeLanguage(elt.name)} className="language-button">
                        <img className='language-image' src={elt.image} alt={elt.name}/>
                    </div>
                ))
            }
        </div>
    );
}

export default LanguagePanel;
