import React, { useEffect } from 'react';
import { Switch, Route, BrowserRouter, useHistory } from 'react-router-dom';
import Worker from '@modules/worker';
import Intro from '@modules/intro';
import NISS from '@modules/niss';
import Incapacity from '@modules/incapacity';
import Recap from '@modules/recap';
import Confirmation from '@modules/confirmation';
import Alternative from '@modules/confirmation/modules/alternative';
import NissImageExemple from '@modules/niss/components/niss-image-exemple';

function Shell() {

  //Attributes
  const history = useHistory();
  
  useEffect(() => {
    //check pathname 
    if(history.location.pathname !== '/niss/exemple') {
      history.replace('/');
    }
  }, [])

  // Rendering
  return (
    <Switch>
        <Route path='/alternative' component={Alternative} />
        <Route path='/confirmation' component={Confirmation} />
        <Route path='/recap' component={Recap} />
        <Route path='/incapacity' component={Incapacity} />
        <Route path='/niss/exemple' component={NissImageExemple} />
        <Route path='/niss' component={NISS} />
        <Route path='/worker' component={Worker} />
        <Route exact path='/' component={Intro} />
    </Switch>
  );
}


export default Shell;
