import Box from '@components/box';
import BoxFooter from '@components/box-footer';
import Footer from '@components/footer';
import Input from '@components/input';
import InputGroup from '@components/input-group';
import PageHeader from '@components/page-header';
import PlacesAutocomplete from '@components/places-autocomplete';
import Select from '@components/select';
import { useTranslation } from '@components/translation-context/TranslationContext';
import { Gender } from '@dtos/WorkerDTO';
import { actions } from '@reducers/requestReducer';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useRequest } from '../../context/requestContext';
import './style.css'
import Employers from '../../i18n/employers.json';

const Worker = () => {
  //Attributes
  const { translate } = useTranslation();
  const history = useHistory();
  const { register, errors, setValue, handleSubmit, getValues, clearErrors } = useForm({ shouldFocusError: false, reValidateMode: 'onSubmit', });
  const { request, dispatch } = useRequest();
  const genders = [
    { value: "Male", label: translate("genderMale") },
    { value: "Female", label: translate("genderFemale") }
  ];
  const employers = Object.keys(Employers).map((elt: any) => {
    // @ts-ignore
    return { value: Employers[elt], label: Employers[elt] }
  });


  // Effects
  useEffect(() => {
    register('workerEmployer', { required: true })
    clearErrors();
  }, []);

  //Functions
  function handleOnClickNext() {
    if (Object.keys(errors).length === 0) {
      history.push("/niss");
    }
  }

  function handleOnClickBack() {
    history.goBack();
  }

  function handleChange(event: any, type: string) {
    clearErrors(event.target.name);
    const action = { type, payload: event?.target.value }
    dispatch(action);
  }

  function retrieveValue(name: string) {
    if (getValues(name)) {
      return getValues(name);
    }
    else {
      switch (name) {
        case "workerFirstName":
          setValue(name, request.worker.firstName);
          return request.worker.firstName;
        case "workerLastName":
          setValue(name, request.worker.lastName);
          return request.worker.lastName;
        case "workerPhoneNumber":
          setValue(name, request.worker.phoneNumber);
          return request.worker.phoneNumber;
        case "workerGender":
          setValue(name, request.worker.gender);
          return request.worker.gender;
        case "workerAddress":
          setValue(name, request.worker.address);
          break;
        case "employer":
          setValue(name, request.worker.employer);
          return request.worker.employer;
      }
    }
  }

  function renderInput(name: string, type?: string) {
    // Attribtues
    const action = (actions as any)[`${name}Changed`];
    const errorMessage = errors[name]?.type === "pattern" ? translate('patternError') : translate('errorInput');
    const isHidden = false;
    const formProps = { required: true };

    // Render
    return (
      <Input
        type={type ? type : "text"}
        name={name}
        isHidden={isHidden}
        value={retrieveValue(name)}
        setValue={setValue}
        onChange={(event) => handleChange(event, action)}
        placeholder={translate(`${name}Placeholder`)}
        error={errors[name]}
        errorMessage={errorMessage}
        register={register}
        formProps={formProps}
      />
    );
  }

  function renderSelect(name: string, options: any) {
    //Attributes
    const action = (actions as any)[`${name}Changed`];
    const errorMessage = errors[name]?.type === "pattern" ? translate('patternError') : translate('errorInput');
    const isHidden = false;
    let formProps = {};
    const employerValue = request.worker.employer;

    if (name === "workerGender") {
      formProps = { required: true, validate: () => request.worker.gender !== Gender.UNKNOWN }
    }
    else if (name === "workerEmployer") {
      formProps = { required: true, validate: () => request.worker.employer !== -1 }
    }
    //Render
    return (
      <Select
        value={name === "workerEmployer" ? employerValue : retrieveValue(name)}
        name={name}
        isHidden={isHidden}
        options={options}
        onChange={(event: any) => handleChange(event, action)}
        placeholder={translate(`${name}Placeholder`)}
        error={errors[name]}
        errorMessage={errorMessage}
        register={register}
        formProps={formProps}
      />
    );
  }

  return (
    <div className="workerFlex">
      <Box>
        <Box type="boxinside">
          <PageHeader subTitle={translate("workerInformation")} />
          <div className="worker-box">
            <InputGroup>
              {renderInput('workerFirstName')}
              <div style={{ width: '10px' }} />
              {renderInput('workerLastName')}
            </InputGroup>
            <InputGroup>
              {renderSelect('workerGender', genders)}
              <div style={{ width: '10px' }} />
              {renderInput('workerPhoneNumber')}
            </InputGroup>
            <InputGroup>
              <PlacesAutocomplete clearError={() => clearErrors("workerAddress")} setValue={setValue} value={retrieveValue('workerAddress')} errors={errors} register={register} />
              <div style={{ width: '10px' }} />
              {renderSelect('workerEmployer', employers)}
            </InputGroup>
            <BoxFooter step={1} errorsLength={Object.keys(errors).length} onClickNext={handleSubmit(handleOnClickNext)} onClickBack={handleOnClickBack} />
          </div>
        </Box>
      </Box>
      <Footer />
    </div>
  );
}

export default Worker;
