import Box from '@components/box';
import Footer from '@components/footer';
import PageHeader from '@components/page-header';
import { useTranslation } from '@components/translation-context/TranslationContext';
import React from 'react';
import './style.css';

export default function AlternativePlus30() {

    //Attributes
    const {translate} = useTranslation();

    //Render
    return (
        <div className="confirmationFlex">
            <Box>
                <Box type="boxinside">
                    <PageHeader subTitle={translate("alternativePlus30")} />
                    <div className="confirmation-box">
                        <p className='conf-alt-text'>{translate('alternativePlus30.description')}</p>
                        <p className='conf-alt-text'>{translate('alternativePlus30.subDescription')}</p>
                    </div>
                </Box>
            </Box>
            <Footer />
        </div>
    )
}