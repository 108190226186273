import RequestDTO from "@dtos/RequestDTO";
import { Gender } from "@dtos/WorkerDTO";
import { CertificateType } from "../enums/CertificateType";
import { IncapacityType } from "../enums/IncapacityType";

export const initialRequest: RequestDTO = {
	id: "",
	companyId: process.env.REACT_APP_COMPANY_ID ?? "",
	worker: {
		firstName: "",
		lastName: "",
		niss: "",
		gender: Gender.UNKNOWN,
		function: "",
		language: "",
		languages: ["fr", "nl"],
		phoneNumber: "",
		address: "",
		employer: -1,
	},
	subEmployer: "",
	startDate: "",
	endDate: "",
	authorizedLeave: -1,
	externalId: "",
	medicheckComment: "",
	doctorComment: "",
	forceLaunch: false,
	incapacityType: IncapacityType.Unknown,
	certificateType: CertificateType.Unknown,
	source: "",
	accidentDate: "",
	accidentHour: "",
};

export const actions = {
	idChanged: "idChanged",
	companyIdChanged: "companyIdChanged",
	workerFirstNameChanged: "firstNameChanged",
	workerLastNameChanged: "lastNameChanged",
	workerNissChanged: "workerNissChanged",
	workerGenderChanged: "genderChanged",
	workerFunctionChanged: "functionChanged",
	workerLanguageChanged: "languageChanged",
	workerLanguagesChanged: "languagesChanged",
	workerPhoneNumberChanged: "phoneNumberChanged",
	workerAddressChanged: "workerAddressChanged",
	workerEmployerChanged: "workerEmployerChanged",
	subEmployerChanged: "subEmployerChanged",
	superiorChanged: "superiorChanged",
	startDateChanged: "startDateChanged",
	endDateChanged: "endDateChanged",
	authorizedLeaveChanged: "authorizedLeaveChanged",
	incapacityTypeChanged: "incapacityTypeChanged",
	certificateTypeChanged: "certificateTypeChanged",
	sourceChanged: "sourceChanged",
	accidentDateChanged: "accidentDateChanged",
	accidentHourChanged: "accidentHourChanged",
};

export interface requestReducerAction {
	type: string;
	payload: any;
}

function reducer(state: RequestDTO, action: requestReducerAction): RequestDTO {
	switch (action.type) {
		case actions.workerFirstNameChanged:
			return { ...state, worker: { ...state.worker, firstName: action.payload } };
		case actions.workerLastNameChanged:
			return { ...state, worker: { ...state.worker, lastName: action.payload } };
		case actions.workerGenderChanged:
			return { ...state, worker: { ...state.worker, gender: action.payload } };
		case actions.workerFunctionChanged:
			return { ...state, worker: { ...state.worker, function: action.payload } };
		case actions.workerLanguageChanged:
			return { ...state, worker: { ...state.worker, language: action.payload } };
		case actions.workerLanguagesChanged:
			return { ...state, worker: { ...state.worker, languages: action.payload } };
		case actions.workerPhoneNumberChanged:
			return { ...state, worker: { ...state.worker, phoneNumber: action.payload } };
		case actions.workerAddressChanged:
			return { ...state, worker: { ...state.worker, address: action.payload } };
		case actions.workerNissChanged:
			return { ...state, worker: { ...state.worker, niss: action.payload } };
		case actions.workerEmployerChanged:
			return { ...state, worker: { ...state.worker, employer: action.payload } };
		case actions.superiorChanged:
			return { ...state, superior: { ...state.superior, ...action.payload } };
		case actions.subEmployerChanged:
			return { ...state, subEmployer: action.payload };
		case actions.startDateChanged:
			return { ...state, startDate: action.payload };
		case actions.endDateChanged:
			return { ...state, endDate: action.payload };
		case actions.accidentDateChanged:
			return { ...state, accidentDate: action.payload };
		case actions.accidentHourChanged:
			return { ...state, accidentHour: action.payload };
		case actions.authorizedLeaveChanged:
			return { ...state, authorizedLeave: action.payload };
		case actions.incapacityTypeChanged:
			return { ...state, incapacityType: action.payload };
		case actions.certificateTypeChanged:
			return { ...state, certificateType: action.payload };
		case actions.sourceChanged:
			return { ...state, source: action.payload };
		default:
			return state;
	}
}

export default { actions, reducer };
