import Box from '@components/box';
import BoxFooter from '@components/box-footer';
import Footer from '@components/footer';
import Input from '@components/input';
import InputGroup from '@components/input-group';
import PageHeader from '@components/page-header';
import Select from '@components/select';
import { useTranslation } from '@components/translation-context/TranslationContext';
import { actions } from '@reducers/requestReducer';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { CertificateType } from '../../enums/CertificateType';
import { useRequest } from '../../context/requestContext';
import './style.css';

const Incapacity = () => {
  //Attributes
  const {translate} = useTranslation();
  const history = useHistory();
  const {request, dispatch} = useRequest();
  const { register, errors, setValue, handleSubmit, getValues, clearErrors } = useForm({ reValidateMode: 'onSubmit' });
  const certificateType = [
    {value: 'FirstCertificate', label: translate('1st-certificate') },
    {value: 'Prolongation', label: translate('prolongation') },
    {value: 'Relapse', label: translate('relapse') },
  ];
  const authorizedLeave = [
    {value: 1, label: translate('sortieTrue')},
    {value:0, label: translate('sortieFalse')}
  ];

  // Effects
  useEffect(() => {

    register("startDate", {required: true});
    register("endDate", {required: true});
    register("authorizedLeave", {required: true});
    register("accidentDate", {required: true});
    register("accidentHour", {required: true});

    clearErrors();
  }, []);
  
  //Functions
  function handleOnClickNext() {
    if(request.startDate === "") {
      console.log("startDate vide.");
    }
    history.push("/recap");
  }

  function handleOnClickBack() {
      history.goBack();
  }

  function handleChange(event: any, type: string) {
    clearErrors(event.target.name);
    
    switch(event.target.name) {
      case 'startDate':
        setValue('endDate', '');
        dispatch({type: 'endDateChanged', payload: ''});
        break;
      default:
        break;
    }

    setValue(event.target.name, event.target.value);
    const action = { type, payload: event?.target.value }
    dispatch(action);
  }

  function retrieveValue(name: string) {
      if (getValues(name)) {
        return getValues(name);
      }
      else {
        switch (name) {
          case "startDate":
              setValue(name, request.startDate);
              break;
          case "endDate":
              setValue(name, request.endDate);
              break;
          case "certificateType":
              setValue(name, request.certificateType);
              return request.certificateType;
          case "authorizedLeave":
              setValue(name, request.authorizedLeave);
              return request.authorizedLeave;
          case "accidentDate":
              setValue(name, request.accidentDate);
              break;
          case "accidentHour":
              setValue(name, request.accidentHour);
              break;
        }
      }
    }

  function renderInput(name: string, type?: string, mask?: string) {
      // Attribtues
      const action = (actions as any)[`${name}Changed`];
      const errorMessage = errors[name]?.type === "pattern" ? translate('patternError') : translate('errorInput');
      const isHidden = false;
      const formProps = { };
      const minValue = name == "endDate" ? new Date(request.startDate) : undefined;

      // Render
      return (
        <Input
          type={type ? type : 'text'}
          name={name}
          isHidden={isHidden}
          value={retrieveValue(name)}
          setValue={setValue}
          onChange={(event) => handleChange(event, action)}
          placeholder={translate(`${name}Placeholder`)}
          error={errors[name]}
          errorMessage={errorMessage}
          formProps={formProps}
          minValue={minValue}
          mask={mask ? mask : ''}
        />
      );
    }
  
    function renderSelect(name: string, options: Array<object>) {
      //Attributes
      const action = (actions as any)[`${name}Changed`];
      const errorMessage = errors[name]?.type === "pattern" ? translate('patternError') : translate('errorInput');
      const isHidden = false;
      let formProps = {};
  
      if (name === "certificateType") {
        formProps = { required: true, validate: () => request.certificateType !== CertificateType.Unknown }
      }
      else if (name === "authorizedLeave") {
        // @ts-ignore
        formProps = { required: true, validate: () => (request.authorizedLeave == 1 || request.authorizedLeave == 0)}
      }
      
      //Render
      return (
        <Select
          value={retrieveValue(name)}
          name={name}
          isHidden={isHidden}
          options={options}
          onChange={(event: any) => handleChange(event, action)}
          placeholder={translate(`${name}Placeholder`)}
          error={errors[name]}
          errorMessage={errorMessage}
          register={register}
          formProps={formProps}
        />
      );
  }

  return(
    <div className="incapacityFlex">
        <Box>
          <Box type="boxinside">
              <PageHeader subTitle={translate("workerIncapacity")} />
              <div className="worker-box">
                <InputGroup>
                  {renderInput('startDate', 'date')}
                  <div style={{ width: '10px' }} />
                  {renderInput('endDate', 'date')}
                </InputGroup>
                <InputGroup>
                  {renderSelect('certificateType', certificateType)}
                  <div style={{ width: '10px' }} />
                  {renderSelect('authorizedLeave', authorizedLeave)}
                </InputGroup>
                <InputGroup>
                  {renderInput('accidentDate', 'date')}
                  <div style={{ width: '10px' }} />
                  {/* {renderInput('accidentHour', 'time')} */}
                  {renderInput('accidentHour', 'text', '99:99')}
                </InputGroup>
                  <BoxFooter step={3} errorsLength={Object.keys(errors).length} onClickNext={handleSubmit(handleOnClickNext)} onClickBack={handleOnClickBack} />
              </div>
          </Box>
      </Box>
      <Footer />
    </div>
  );
}

export default Incapacity;